<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/ai">AI Tools</router-link> |
    <router-link to="/stacks">Technology Stacks</router-link> |
    <router-link to="/about">About</router-link> |
    <a href="https://algoquest.dev" target="_blank" class="external-link">AlgoQuest</a>

  </nav>
  <router-view />

  <footer class="container">
    <small>
      <a href="#">Privacy Policy</a> • <a href="#">Terms of Use</a>
    </small>
  </footer>
</template>



<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .external-link {
    margin-left: 10px;
  }

}
</style>
