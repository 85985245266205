<template>
  <div class="p-4">
    <h1 class="text-xl font-bold mb-4">Text Utility</h1>

    <div class="mb-4">
      <textarea v-model="inputText" class="border border-gray-300 rounded p-2 w-full h-32" placeholder="Enter your text here"></textarea>
    </div>

    <div class="mb-4">
      <button @click="toggleCase" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Toggle Case
      </button>
    </div>

    <div>
      <p><strong>String Length:</strong> {{ stringLength }}</p>
      <p><strong>Word Count:</strong> {{ wordCount }}</p>
      <p><strong>Toggled Text:</strong> {{ toggledText }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'TextUtility',
  setup() {
    const inputText = ref('');
    const toggledText = ref('');

    const stringLength = computed(() => inputText.value.length);
    const wordCount = computed(() => inputText.value.trim().split(/\s+/).filter(word => word).length);

    function toggleCase() {
      toggledText.value = inputText.value.split('').map(char => {
        return char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase();
      }).join('');
    }

    return { inputText, toggledText, stringLength, wordCount, toggleCase };
  }
});
</script>

<style scoped>
/* Tailwind utility classes handle most styling */
</style>
