<template>
    <div class="p-4 m-4">
        <h1 class="text-2xl font-bold mb-6 text-center">Technology Stacks</h1>
        <section v-for="(category, categoryName) in technologyStacks" :key="categoryName" class="mb-10">
            <h2 :style="{ color: stringToColor(categoryName) }" class="text-lg font-semibold mb-4">
                {{ formatCategoryName(categoryName) }}
            </h2>
            <table class="min-w-full table-auto border-collapse border border-gray-300">
                <thead :style="{ backgroundColor: stringToColor(categoryName, 0.2) }">
                    <tr>
                        <th class="border border-gray-300 p-2">Name</th>
                        <th class="border border-gray-300 p-2">Description</th>
                        <th v-if="hasLanguage(category)" class="border border-gray-300 p-2">Language</th>
                        <th class="border border-gray-300 p-2">When to Choose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tech in category" :key="tech.name"
                        :style="{ backgroundColor: stringToColor(categoryName, 0.1) }">
                        <td class="border border-gray-300 p-2">{{ tech.name }}</td>
                        <td class="border border-gray-300 p-2">{{ tech.description }}</td>
                        <td v-if="hasLanguage(category)" class="border border-gray-300 p-2">{{ tech.language }}</td>
                        <td class="border border-gray-300 p-2">{{ tech.whenToChoose }}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Define the type for the JSON data
interface TechnologyStack {
    name: string;
    description: string;
    language?: string;
    whenToChoose: string;
}

// Import the JSON data
import technologyStacks from '@/components/technologyStacks.json';

export default defineComponent({
    name: 'TechnologyStacks',
    data() {
        return {
            technologyStacks: technologyStacks as unknown as Record<string, TechnologyStack[]>
        };
    },
    methods: {
        formatCategoryName(categoryName: string) {
            // Converts camelCase to normal words, e.g., 'frontEndTech' to 'Front End Tech'
            return categoryName.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
        },
        hasLanguage(category: TechnologyStack[]) {
            return category.some(tech => tech.language !== undefined && tech.language !== null);
        },
        stringToColor(str: string, opacity = 1) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            const color = `rgba(${(hash & 0xFF)}, ${(hash >> 8) & 0xFF}, ${(hash >> 16) & 0xFF}, ${opacity})`;
            return color;
        }
    }
});
</script>

<style scoped>
/* Tailwind utility classes handle most styling */

table {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

th,
td {
    text-align: left;
    vertical-align: top;
}

th {
    font-weight: 600;
}

td {
    font-size: 0.875rem;
    /* Adjust font size as needed */
}

tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
}</style>
