<template>
  <div class="image-resizer">
    <h1 class="text-3xl font-bold">Image Resizer</h1>
    <input type="file" @change="onFileChange" accept="image/*" />
    <div v-if="image">
      <div class="resize-controls flex items-center mt-4">
        <label for="width" class="mr-2">Width:</label>
        <input type="number" v-model.number="width" class="w-16 border border-gray-300 rounded px-2 py-1" />
        <label for="height" class="ml-4 mr-2">Height:</label>
        <input type="number" v-model.number="height" class="w-16 border border-gray-300 rounded px-2 py-1" />
        <button class="resize-btn bg-green-500 text-white px-4 py-2 rounded ml-4" @click="resizeImage">Resize</button>
      </div>
      <img :src="image" alt="Preview" class="preview mt-4" />
      <button class="download-btn bg-green-500 text-white px-4 py-2 rounded mt-4" @click="downloadImage">Download</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { saveAs } from 'file-saver';

export default defineComponent({
  name: 'ImageResizer',
  setup() {
    const image = ref<string>('');
    const width = ref<number>(0);
    const height = ref<number>(0);
    let originalImage: File | null = null;

    function onFileChange(e: Event) {
      const target = e.target as HTMLInputElement;
      if (target.files) {
        originalImage = target.files[0];
        image.value = URL.createObjectURL(originalImage);
      }
    }

    function resizeImage() {
      if (!originalImage) return;

      const canvas = document.createElement('canvas');
      canvas.width = width.value;
      canvas.height = height.value;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, width.value, height.value);
          image.value = canvas.toDataURL('image/png');
        };
        img.src = URL.createObjectURL(originalImage);
      }
    }

    function downloadImage() {
      if (image.value) {
        saveAs(image.value, "resized-image.png");
      }
    }

    return { image, width, height, onFileChange, resizeImage, downloadImage };
  }
});
</script>

<style scoped>
.image-resizer {
  text-align: center;
  margin-top: 20px;
}

.resize-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.resize-btn,
.download-btn {
  @apply bg-green-500 text-white px-4 py-2 rounded;
  margin-left: 10px;
}

.resize-btn:hover,
.download-btn:hover {
  @apply bg-green-600;
}

.preview {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}
</style>
