import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFound from '@/components/NotFound.vue';
import About from '@/views/AboutView.vue';
import QRCodeGenerator from '@/components/QRCodeGenerator.vue';
import TimestampGenerator from '@/components/TimestampGenerator.vue';
import Base64Converter from '@/components/Base64Converter.vue';
import UUIDGenerator from '@/components/UUIDGenerator.vue';
import JSONFormatter from '@/components/JSONFormatter.vue';
import XMLFormatter from '@/components/XMLFormatter.vue';
import ImageResizer from '@/components/ImageResizer.vue';
import MarkdownEditor from '@/components/MarkdownEditor.vue';
import WebStorageExplorer from '@/components/WebStorageExplorer.vue';
import PasswordGenerator from '@/components/PasswordGenerator.vue';
import BrowserDetails from '@/components/BrowserDetails.vue';
import ColorPicker from '@/components/ColorPicker.vue';
import TextUtility from '@/components/TextUtility.vue';
import JWTDecoder from '@/components/JWTDecoder.vue';
import HashingTools from '@/components/HashingTools.vue';
import GitCheatsheet from '@/components/GitCheatsheet.vue';
import TechlogogyStacks from '@/views/TechnologyStacks.vue';
import AITools from '@/components/AITools.vue';
import PromptTemplateBuilder from '@/components/PromptTemplateBuilder.vue';
import VSCodeHotkeys from '@/components/VSCodeHotkeys.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tools/qrcode',
    name: 'qrcode',
    component: QRCodeGenerator
  },
  {
    path: '/tools/timestamp',
    name: 'timestamp',
    component: TimestampGenerator
  },
  {
    path: '/tools/base64',
    name: 'base64',
    component: Base64Converter
  },
  {
    path: '/tools/uuid',
    name: 'uuid',
    component: UUIDGenerator
  },
  {
    path: '/tools/json',
    name: 'json',
    component: JSONFormatter
  },
  {
    path: '/tools/xml',
    name: 'xml',
    component: XMLFormatter
  },
  {
    path: '/tools/image-resizer',
    name: 'image-resizer',
    component: ImageResizer
  },
  {
    path: '/tools/markdown',
    name: 'markdown-editor',
    component: MarkdownEditor
  },
  {
    path: '/tools/web-storage',
    name: 'web-storage-explorer',
    component: WebStorageExplorer
  },
  {
    path: '/tools/password',
    name: 'password-generator',
    component: PasswordGenerator
  },
  {
    path: '/tools/browser-details',
    name: 'browser-details',
    component: BrowserDetails
  },
  {
    path: '/tools/color-picker',
    name: 'color-picker',
    component: ColorPicker
  },
  {
    path: '/tools/text-utility',
    name: 'text-utils',
    component: TextUtility
  },
  {
    path: '/tools/jwt-decoder',
    name: 'jwt-decoder',
    component: JWTDecoder
  },
  {
    path: '/tools/hashing-tools',
    name: 'hashing-tools',
    component: HashingTools
  },
  {
    path: '/tools/git-cheatsheet',
    name: 'git-cheatsheet',
    component: GitCheatsheet
  },
  {
    path: '/tools/prompt-template-builder',
    name: 'prompt-template-builder',
    component: PromptTemplateBuilder
   },
  {
    path: '/tools/vscode-hotkeys',
    name: 'vscode-hotkeys',
    component: VSCodeHotkeys
  },
  {
    path: '/stacks',
    name: 'stacks',
    component: TechlogogyStacks
  },
  {
    path: '/ai',
    name: 'ai',
    component: AITools
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/:pathMatch(.*)*', // or '/:catchAll(.*)' for Vue Router 3
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
