<template>
    <div class="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      <router-link to="/">Go to Home</router-link>
    </div>
  </template>
  
  <script lang="ts">
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  
  .not-found h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .not-found p {
    margin-bottom: 20px;
  }
  
  .not-found a {
    color: #007bff;
    text-decoration: none;
  }
  </style>
  