<template>
    <div class="p-4 m-4">
        <h1 class="text-xl font-bold text-center mb-6">Git Cheatsheet</h1>
        <div class="overflow-x-auto">
            <table class="table-auto w-full text-left border-collapse border border-gray-400">
                <thead>
                    <tr class="bg-gray-100">
                        <th class="border border-gray-300 p-2">Command</th>
                        <th class="border border-gray-300 p-2">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(description, command) in gitCommands" :key="command">
                        <td class="border border-gray-300 p-2">{{ command }}</td>
                        <td class="border border-gray-300 p-2">{{ description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GitCheatsheet',
    data() {
        return {
            gitCommands: {
                'git init': 'Initialize a new Git repository',
                'git clone <url>': 'Clone a repository from a URL',
                'git config --global user.name "<name>"': 'Set a name that will be attached to your commits and tags',
                'git config --global user.email "<email>"': 'Set an email address that will be attached to your commits and tags',
                'git status': 'Show the working tree status',
                'git add <file/directory>': 'Add file contents to the index',
                'git mv <old> <new>': 'Move or rename a file, a directory, or a symlink',
                'git rm <file>': 'Remove files from the working tree and from the index',
                'git commit -m "<message>"': 'Record changes to the repository with a message',
                'git push [alias] [branch]': 'Update remote refs along with associated objects',
                'git pull [alias] [branch]': 'Fetch from and integrate with another repository or a local branch',
                'git branch': 'List, create, or delete branches',
                'git branch -d <branch>': 'Delete a branch',
                'git checkout <branch/tag>': 'Switch branches or restore working tree files',
                'git merge <branch>': 'Join two or more development histories together',
                'git rebase [branch]': 'Reapply commits on top of another base tip',
                'git tag': 'Create, list, delete or verify a tag object signed with GPG',
                'git fetch <remote>': 'Download objects and refs from another repository',
                'git log': 'Show commit logs',
                'git log --oneline': 'Show commit logs in a compact single line format',
                'git diff [file]': 'Show changes between commits, commit and working tree, etc',
                'git diff --staged': 'Show staged changes compared to the named commit',
                'git stash': 'Stash the changes in a dirty working directory away',
                'git stash apply': 'Apply a single stashed state from the stash list',
                'git reset [file]': 'Reset current HEAD to the specified state',
                'git reset --hard <commit>': 'Reset the index and working tree to the specified commit',
                'git revert <commit>': 'Create a new commit that undoes all of the changes made in <commit>, then apply it to the current branch',
                'git rm [file]': 'Remove files from the working tree and from the index',
                'git cherry-pick <commit>': 'Apply the changes introduced by some existing commits',
                'git blame <file>': 'Show what revision and author last modified each line of a file',
                'git bisect start': 'Use binary search to find the commit that introduced a bug',
                'git bisect good <commit>': 'Mark the current state as good',
                'git bisect bad <commit>': 'Mark the current state as bad',
                'git bisect reset': 'Exit bisect mode',
                'git remote add <name> <url>': 'Add a remote named <name> for the repository at <url>',
                'git remote show <remote>': 'Gives some information about the remote <remote>',
                'git remote rename <old> <new>': 'Rename a remote',
                'git remote remove <remote>': 'Remove the remote named <remote>',
                'git remote prune <name>': 'Deletes all stale tracking branches under <name>',
                'git submodule add <url> <path>': 'Add a git repository as a submodule',
                'git submodule init': 'Initialize the submodules recorded in the index',
                'git submodule update': 'Update the registered submodules to match the superproject'
            }

            // More commands can be added as needed

        };
    }
});
</script>
  
<style scoped>
/* Tailwind utility classes handle most styling */
</style>
  