<template>
    <div class="p-4 max-w-4xl mx-auto">
        <h1 class="text-2xl font-bold mb-6">VS Code Keyboard Shortcuts for Windows/Linux & macOS</h1>
        <div class="overflow-x-auto">
            <table class="min-w-full table-auto text-left border-collapse border border-gray-400">
                <thead class="bg-gray-200">
                    <tr>
                        <th class="border border-gray-300 p-2">Action</th>
                        <th class="border border-gray-300 p-2">Windows/Linux</th>
                        <th class="border border-gray-300 p-2">macOS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="action in hotkeys" :key="action.description" class="hover:bg-gray-100">
                        <td class="border border-gray-300 p-2">{{ action.description }}</td>
                        <td class="border border-gray-300 p-2">{{ action.winLinux }}</td>
                        <td class="border border-gray-300 p-2">{{ action.mac }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'VSCodeHotkeys',
    data() {
        return {
            hotkeys: [
                {
                    description: 'Quick Open, Go to File...',
                    winLinux: 'Ctrl + P',
                    mac: 'Cmd + P'
                },

                {
                    description: 'Cut line (empty selection)',
                    winLinux: 'Ctrl + X',
                    mac: 'Cmd + X'
                },
                {
                    description: 'Copy line (empty selection)',
                    winLinux: 'Ctrl + C',
                    mac: 'Cmd + C'
                },
                {
                    description: 'Move line up/down',
                    winLinux: 'Alt + ↑ / ↓',
                    mac: 'Option + ↑ / ↓'
                },
                {
                    description: 'Copy line up/down',
                    winLinux: 'Shift + Alt + ↓ / ↑',
                    mac: 'Shift + Option + ↓ / ↑'
                },

                {
                    description: 'Toggle Tab moves focus',
                    winLinux: 'Ctrl + M',
                    mac: 'Cmd + M'
                },
                {
                    description: 'Toggle Sidebar visibility',
                    winLinux: 'Ctrl + B',
                    mac: 'Cmd + B'
                },

                {
                    description: 'Toggle Integrated Terminal',
                    winLinux: 'Ctrl + `',
                    mac: 'Cmd + `'
                },
                {
                    "description": "Auto Fix",
                    "winLinux": "Ctrl + .",
                    "mac": "Cmd + ."
                },
                {
                    description: 'Save File',
                    winLinux: 'Ctrl + S',
                    mac: 'Cmd + S'
                },
                {
                    description: 'Save All Files',
                    winLinux: 'Ctrl + K S',
                    mac: 'Cmd + Option + S'
                },
                {
                    description: 'Close editor',
                    winLinux: 'Ctrl + W',
                    mac: 'Cmd + W'
                },
                {
                    description: 'Go to Line...',
                    winLinux: 'Ctrl + G',
                    mac: 'Cmd + G'
                },

                {
                    description: 'Toggle Full Screen',
                    winLinux: 'F11',
                    mac: 'Cmd + Ctrl + F'
                },
                {
                    description: 'Zoom in/out',
                    winLinux: 'Ctrl + +/-',
                    mac: 'Cmd + +/-'
                },
                {
                    description: 'Undo',
                    winLinux: 'Ctrl + Z',
                    mac: 'Cmd + Z'
                },
                {
                    description: 'Redo',
                    winLinux: 'Ctrl + Y',
                    mac: 'Cmd + Shift + Z'
                },
                {
                    "description": "Toggle search details",
                    "winLinux": "Ctrl + Shift + J",
                    "mac": "Cmd + Shift + J"
                },

                {
                    "description": "Close window/instance",
                    "winLinux": "Ctrl + Shift + W",
                    "mac": "Cmd + Shift + W"
                },
                {
                    "description": "Open Command Palette",
                    "winLinux": "Ctrl + Shift + P",
                    "mac": "Cmd + Shift + P"
                },
                {
                    description: 'Find in files',
                    winLinux: 'Ctrl + Shift + F',
                    mac: 'Cmd + Shift + F'
                },

                {
                    "description": "Show Explorer / Toggle focus",
                    "winLinux": "Ctrl + Shift + E",
                    "mac": "Cmd + Shift + E"
                },
                {
                    "description": "Open new window/instance",
                    "winLinux": "Ctrl + Shift + N",
                    "mac": "Cmd + Shift + N"
                },
                {
                    "description": "Open User Settings",
                    "winLinux": "Ctrl + Shift + ,",
                    "mac": "Cmd + ,"
                },
                {
                    "description": "Open new terminal",
                    "winLinux": "Ctrl + Shift + `",
                    "mac": "Cmd + Shift + `"
                },
                {
                    "description": "Save File As...",
                    "winLinux": "Ctrl + Shift + S",
                    "mac": "Cmd + Shift + S"
                },
                {
                    "description": "Close editor",
                    "winLinux": "Ctrl + W",
                    "mac": "Cmd + W"
                },
                {
                    "description": "Open new file",
                    "winLinux": "Ctrl + N",
                    "mac": "Cmd + N"
                },


                {
                    "description": "Close workspace",
                    "winLinux": "Ctrl + F4",
                    "mac": "Cmd + F4"
                },
                {
                    "description": "Open settings",
                    "winLinux": "Ctrl + ,",
                    "mac": "Cmd + ,"
                },

                {
                    "description": "Open Keyboard Shortcuts",
                    "winLinux": "Ctrl + K Ctrl + S",
                    "mac": "Cmd + K Cmd + S"
                },
                {
                    "description": "Open Keymap",
                    "winLinux": "Ctrl + K Ctrl + M",
                    "mac": "Cmd + K Cmd + M"
                },

                {
                    "description": "Search and replace",
                    "winLinux": "Ctrl + H",
                    "mac": "Cmd + Option + F"
                },
                {
                    "description": "Find next",
                    "winLinux": "F3",
                    "mac": "Cmd + G"
                },
                {
                    "description": "Find previous",
                    "winLinux": "Shift + F3",
                    "mac": "Cmd + Shift + G"
                },

                {
                    "description": "Replace next",
                    "winLinux": "Alt + Enter",
                    "mac": "Ctrl + Enter"
                },
                {
                    "description": "Column Select",
                    "winLinux": "Shift + Alt + (drag mouse)",
                    "mac": "Cmd + Shift + (drag mouse)"
                },




            ]


        };
    }
});
</script>

<style scoped>
/* Tailwind CSS for table styling */
</style>