<template>
    <div class="p-4">
      <h1 class="text-xl font-bold mb-4">Web Storage Explorer</h1>
      <div>
        <div class="mb-4">
          <button @click="loadLocalStorage" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Load Local Storage
          </button>
        </div>
        <div class="mb-4">
          <button @click="loadSessionStorage" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Load Session Storage
          </button>
        </div>
        <div v-for="(value, key) in storageData" :key="key" class="mb-2">
          <strong>{{ key }}:</strong> {{ value }}
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, reactive } from 'vue';
  
  export default defineComponent({
    name: 'WebStorageExplorer',
    setup() {
      const storageData = reactive({});
  
      function loadLocalStorage() {
        Object.assign(storageData, {...localStorage});
      }
  
      function loadSessionStorage() {
        Object.assign(storageData, {...sessionStorage});
      }
  
      return { storageData, loadLocalStorage, loadSessionStorage };
    }
  });
  </script>
  
  <style>
  /* Tailwind utility classes handle most styling */
  </style>
  