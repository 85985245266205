<template>
    <div class="uuid-generator m-16">
      <h2>UUID Generator</h2>
      <button class="generate-btn" @click="generateUUID">Generate UUID</button>
      <div v-if="uuid">
        <p>UUID: {{ uuid }}</p>
        <button class="copy-btn" @click="copyToClipboard(uuid)">Copy</button>
      </div>
      <button class="generate-btn" @click="generateShortUUID">Generate Short UUID</button>
      <div v-if="shortUuid">
        <p>Short UUID: {{ shortUuid }}</p>
        <button class="copy-btn" @click="copyToClipboard(shortUuid)">Copy</button>
      </div>
      <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { v4 as uuidv4 } from 'uuid';
  
  export default defineComponent({
    name: 'UUIDGenerator',
    setup() {
      const uuid = ref('');
      const shortUuid = ref('');
      const showSnackbar = ref(false);
  
      const generateUUID = () => {
        uuid.value = uuidv4();
      };
  
      const generateShortUUID = () => {
        shortUuid.value = uuidv4().split('-')[0];
      };
  
      const copyToClipboard = async (text: string) => {
        try {
          await navigator.clipboard.writeText(text);
          showSnackbar.value = true;
          setTimeout(() => { showSnackbar.value = false; }, 3000); // Hide after 3 seconds
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      };
  
      return { uuid, shortUuid, showSnackbar, generateUUID, generateShortUUID, copyToClipboard };
    }
  });
  </script>
  
  <style scoped>
  .uuid-generator {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .generate-btn, .copy-btn {
    padding: 10px 15px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .generate-btn {
    background-color: #007bff;
    color: white;
  }
  
  .generate-btn:hover {
    background-color: #0056b3;
  }
  
  .copy-btn {
    background-color: #28a745;
    color: white;
  }
  
  .copy-btn:hover {
    background-color: #1e7e34;
  }
  
  .snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    transition: visibility 0.3s, opacity 0.3s linear;
    opacity: 0;
  }
  
  .snackbar.show {
    visibility: visible;
    opacity: 1;
  }
  </style>
  