<template>
  <div class="p-4">
    <h1 class="text-xl font-bold mb-4">Color Utility</h1>
    <div class="mb-4">
      <input type="color" v-model="selectedColor" class="border border-gray-300 rounded p-2">
      <button @click="generateRandomColor"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2">
        Random Color
      </button>
    </div>
    <div class="color-display p-4 rounded" :style="{ backgroundColor: selectedColor }">
      Selected Color: {{ selectedColor }} {{ selecteRgbColor }}
    </div>

    <div class="mb-4">
      <label class="block mb-2">HEX:</label>
      <input type="text" v-model="hexColor" @input="convertToRGB" class="border border-gray-300 rounded p-2 w-full">
    </div>

    <div class="mb-4">
      <label class="block mb-2">RGB:</label>
      <input type="text" v-model="rgbColor" @input="convertToHEX" class="border border-gray-300 rounded p-2 w-full">
    </div>

    <div class="color-display p-4 rounded" :style="{ backgroundColor: hexColor }">
      Display Color
    </div>
  </div>
</template>
  
<script lang="ts">
import { Ref, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'ColorPicker',
  setup() {
    const selectedColor = ref('#ffffff');
    const selecteRgbColor = ref('rgb(255, 255, 255)');
    const hexColor = ref('#ffffff');
    const rgbColor = ref('rgb(255, 255, 255)');

    function convertToRGBBasic(inputColor: Ref<string>): string {
      if (/^#([0-9A-F]{3}){1,2}$/i.test(inputColor.value)) {
        let color = inputColor.value.substring(1);
        if (color.length === 3) {
          color = color.split('').map(c => c + c).join('');
        }
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        return `rgb(${r}, ${g}, ${b})`;
      }
      return '';
    }

    function convertToRGB() {
      rgbColor.value = convertToRGBBasic(hexColor);
    }

    function convertToSelectedRGB() {
      selecteRgbColor.value = convertToRGBBasic(selectedColor);
    }

    function convertToHEX() {
      const match = rgbColor.value.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
      if (match) {
        hexColor.value = '#' + [1, 2, 3].map(i => ('0' + parseInt(match[i]).toString(16)).slice(-2)).join('');
      }
    }

    watch(hexColor, convertToRGB, { immediate: true });

    watch(selectedColor, convertToSelectedRGB, { immediate: true });

    function generateRandomColor() {
      const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      selectedColor.value = randomColor;
    }

    return { selectedColor, selecteRgbColor, generateRandomColor, hexColor, rgbColor, convertToRGB, convertToHEX };
  }
});
</script>
  
<style scoped>
.color-display {
  border: 1px solid #ccc;
  text-align: center;
  color: #333;
  margin-top: 20px;
}
</style>
  