<template>
    <div class="json-formatter m-16">
      <h2>JSON Formatter</h2>
      <textarea class="json-input" v-model="jsonInput" placeholder="Enter JSON"></textarea>
      <div class="button-container">
        <button class="format-btn" @click="formatJSON">Format JSON</button>
      </div>
      <pre class="json-output" v-if="formattedJson">{{ formattedJson }}</pre>
      <button class="copy-btn" v-if="formattedJson" @click="copyToClipboard(formattedJson)">Copy Formatted JSON</button>
      <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'JSONFormatter',
    setup() {
      const jsonInput = ref('');
      const formattedJson = ref('');
      const showSnackbar = ref(false);
  
      const formatJSON = () => {
        try {
          const obj = JSON.parse(jsonInput.value);
          formattedJson.value = JSON.stringify(obj, null, 2);
        } catch (error) {
          formattedJson.value = 'Invalid JSON';
        }
      };
  
      const copyToClipboard = async (text: string) => {
        try {
          await navigator.clipboard.writeText(text);
          showSnackbar.value = true;
          setTimeout(() => { showSnackbar.value = false; }, 3000); // Hide after 3 seconds
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      };
  
      return { jsonInput, formattedJson, formatJSON, copyToClipboard, showSnackbar };
    }
  });
  </script>
  
  <style scoped>
  .json-formatter {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .json-input {
    width: 80%;
    height: 100px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
  }
  
  .format-btn, .copy-btn {
    padding: 10px 15px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .button-container {
  text-align: center;
  margin: 15px 0;
}

.format-btn {
  background-color: #ff9800; /* Different color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.format-btn:hover {
  background-color: #e68900;
}

  
  .copy-btn {
    background-color: #28a745;
    color: white;
  }
  
  .copy-btn:hover {
    background-color: #1e7e34;
  }
  
  .json-output {
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    transition: visibility 0.3s, opacity 0.3s linear;
    opacity: 0;
  }
  
  .snackbar.show {
    visibility: visible;
    opacity: 1;
  }
  </style>
  