<template>
  <div class="timestamp-generator">
    <h2>Time Stamp Generator</h2>
    <button class="generate-btn" @click="generateTimestamp">Generate Timestamp</button>
    <div v-if="timestamps.iso" class="timestamp-display">
      <p>ISO Format: <span class="timestamp">{{ timestamps.iso }}</span></p>
      <button class="copy-btn" @click="copyToClipboard(timestamps.iso)">Copy</button>
    </div>
    <div v-if="timestamps.numeral" class="timestamp-display">
      <p>Numeral Timestamp (ms): <span class="timestamp">{{ timestamps.numeral }}</span></p>
      <button class="copy-btn" @click="copyToClipboard(timestamps.numeral)">Copy</button>
    </div>
    <div v-if="timestamps.seconds" class="timestamp-display">
      <p>Unix Timestamp (s): <span class="timestamp">{{ timestamps.seconds }}</span></p>
      <button class="copy-btn" @click="copyToClipboard(timestamps.seconds)">Copy</button>
    </div>
    <div v-if="timestamps.timezone" class="timestamp-display">
      <p>Timezone: <span class="timestamp">{{ timestamps.timezone }}</span></p>
      <button class="copy-btn" @click="copyToClipboard(timestamps.timezone)">Copy</button>
    </div>
    <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';

export default defineComponent({
  name: 'TimestampGenerator',
  setup() {
    const timestamps = reactive({
      iso: '',
      numeral: '',
      seconds: '', // Unix timestamp in seconds
      timezone: ''
    });

    const showSnackbar = ref(false);

    const generateTimestamp = () => {
      const now = new Date();
      timestamps.iso = now.toISOString();
      timestamps.numeral = now.getTime().toString(); // Milliseconds
      timestamps.seconds = Math.floor(now.getTime() / 1000).toString(); // Seconds
      timestamps.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const copyToClipboard = async (text: string) => {
      try {
        await navigator.clipboard.writeText(text);
        showSnackbar.value = true;
        setTimeout(() => showSnackbar.value = false, 3000); // Hide after 3 seconds
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };

    return { timestamps, generateTimestamp, copyToClipboard, showSnackbar };
  }
});
</script>

<style>
.timestamp-generator {
  @apply bg-gray-100 rounded-lg p-8 m-16 shadow-md text-center mb-8 ;
}

.timestamp-generator h2 {
  @apply text-gray-700 mb-6;
}

.generate-btn {
  @apply bg-red-500 text-white px-4 py-2 rounded-md cursor-pointer transition duration-200;
}

.generate-btn:hover {
  @apply bg-red-600;
}

.timestamp-display {
  @apply bg-white p-4 rounded-md shadow-sm mt-6;
}

.timestamp {
  @apply font-bold;
}

.copy-btn {
  @apply bg-green-500 text-white px-2 py-1 rounded-md cursor-pointer transition duration-200 mt-2;
}

.copy-btn:hover {
  @apply bg-green-600;
}

.snackbar {
  @apply invisible min-w-64 bg-gray-800 text-white text-center rounded-md py-4 px-6 fixed z-10 left-1/2 bottom-8 transform -translate-x-1/2;
}

.snackbar.show {
  @apply visible;
}
</style>
