import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between p-4" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.markdown) = $event)),
      class: "w-1/2 h-64 p-2 border border-gray-300 rounded shadow",
      placeholder: "Enter markdown"
    }, "\n      ", 512), [
      [_vModelText, _ctx.markdown]
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.renderedMarkdown,
      class: "w-1/2 border border-gray-300 rounded shadow p-2 bg-gray-100 overflow-auto"
    }, null, 8, _hoisted_2)
  ]))
}