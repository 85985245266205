import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolCard = _resolveComponent("ToolCard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tools, (tool) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: tool.route,
          key: tool.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ToolCard, { tool: tool }, null, 8, ["tool"])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}