<template>
    <div class="flex justify-between p-4">
      <textarea v-model="markdown" 
                class="w-1/2 h-64 p-2 border border-gray-300 rounded shadow"
                placeholder="Enter markdown">
      </textarea>
      <div v-html="renderedMarkdown" 
           class="w-1/2 border border-gray-300 rounded shadow p-2 bg-gray-100 overflow-auto">
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed, ref } from 'vue';
  import MarkdownIt from 'markdown-it';
  
  export default defineComponent({
    name: 'MarkdownEditor',
    setup() {
      const markdown = ref('');
      const md = new MarkdownIt();
  
      const renderedMarkdown = computed(() => {
        return md.render(markdown.value);
      });
  
      return { markdown, renderedMarkdown };
    }
  });
  </script>
  
  <style>
  /* Tailwind CSS styles or any other custom styles */
  </style>
  