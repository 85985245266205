<template>
  <div class="base64-converter m-16">
    <h2>Base64 Converter</h2>
    <textarea class="base64-textarea" v-model="base64Text" placeholder="Enter text"></textarea>
    <div class="buttons">
      <button class="action-btn" @click="encodeBase64">Encode</button>
      <button class="action-btn" @click="decodeBase64">Decode</button>
    </div>
    <div class="result-display" v-if="base64Result">
      <p>Result:</p>
      <div class="result">{{ base64Result }}</div>
      <button class="copy-btn" @click="copyToClipboard(base64Result)">Copy Result</button>
    </div>
    <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Base64Converter',
  setup() {
    const base64Text = ref('');
    const base64Result = ref('');
    const showSnackbar = ref(false);

    const encodeBase64 = () => {
      base64Result.value = btoa(base64Text.value);
    };

    const decodeBase64 = () => {
      try {
        base64Result.value = atob(base64Text.value);
      } catch (error) {
        base64Result.value = 'Invalid Base64 string';
      }
    };

    const copyToClipboard = async (text: string) => {
      try {
        await navigator.clipboard.writeText(text);
        showSnackbar.value = true;
        setTimeout(() => showSnackbar.value = false, 3000); // Hide after 3 seconds
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };

    return { base64Text, base64Result, encodeBase64, decodeBase64, copyToClipboard, showSnackbar };
  }
});
</script>

<style scoped>
/* ... (existing styles, plus new styles for .copy-btn) */
.copy-btn {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.copy-btn:hover {
  background-color: #1e7e34;
}
</style>


<style scoped>
.base64-converter {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  text-align: center;
  margin-bottom: 20px;
}

.base64-textarea {
  width: 80%;
  height: 100px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
}

.buttons {
  margin-bottom: 15px;
}

.action-btn {
  background-color: #2196f3; /* Change to a different material color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s;
}

.action-btn:hover {
  background-color: #1976d2; /* Change to a different material color */
}

.result-display {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.result {
  word-break: break-all;
}


.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
}
</style>
