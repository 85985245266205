<template>
    <div class="max-w-xl mx-auto p-5">
        <h1 class="text-2xl font-bold text-center mb-6">LLM Prompt Template Tool for Developers</h1>

        <div class="mb-8">
            <div class="relative">
                <select v-model="selectedTemplate"
                    class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                    <option disabled value="">Please select one</option>
                    <option v-for="template in templates" :key="template.name" :value="template">
                        {{ template.name }}
                    </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>

            <textarea v-model="userInput" placeholder="Enter your original text or code here"
                class="w-full p-2 mt-4 border border-gray-300 rounded-md h-32"></textarea>

            <div class="text-center mt-4">
                <button @click="generateFinalText"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                    Generate Final Text
                </button>
                <button @click="clearInputs" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Clear
                </button>
                <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
            </div>
        </div>

        <div v-if="finalText" class="mt-4">
            <h2 class="text-xl font-semibold mb-2">Final Text:</h2>
            <pre class="p-4 bg-gray-100 rounded-md">{{ finalText }}</pre>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';

interface Template {
    name: string;
    content: string;
}

export default defineComponent({
    name: 'LLMPromptTemplateTool',
    setup() {
        const showSnackbar = ref(false);
        const templates: Template[] = [
            {
                name: "Check Grammar and Give a Revised Version",
                content: `Original text:\n\n// Your input here\n\nPlease check the grammar and provide a revised version.`
            },
            {
                name: "Debugging a Function",
                content: `Describe the function behavior and expected output:\n\n// Your input here\n\nWhy isn't this function working as expected?`
            },
            {
                name: "Generating API Documentation",
                content: `Provide API endpoint details:\n\n// Your input here\n\nGenerate documentation for this API endpoint.`
            },
            {
                name: "Code Optimization",
                content: `Provide code snippet:\n\n// Your input here\n\nHow can this code be optimized for better performance?`
            },
            {
                name: "Writing Unit Tests",
                content: `Provide function or component code:\n\n// Your input here\n\nWrite unit tests for this code.`
            },
            {
                name: "Explaining Code Block",
                content: `Provide a complex code block:\n\n// Your input here\n\nExplain how this code works.`
            },
            {
                name: "Error Handling Improvement",
                content: `Provide a code snippet with basic error handling:\n\n// Your input here\n\nHow can error handling be improved in this code?`
            },
            {
                name: "Refactoring Suggestions",
                content: `Provide a code snippet:\n\n// Your input here\n\nSuggest ways to refactor this code for better readability and efficiency.`
            },
            {
                name: "Algorithm Explanation",
                content: `Describe an algorithm or provide pseudocode:\n\n// Your input here\n\nExplain this algorithm and its time complexity.`
            },
            {
                name: "Security Vulnerability Check",
                content: `Provide code snippet or project details:\n\n// Your input here\n\nAre there any security vulnerabilities in this code?`
            },
            {
                name: "Design Pattern Usage",
                content: `Describe a software design problem:\n\n// Your input here\n\nWhich design pattern can be used to solve this problem and why?`
            },

        ];
        const selectedTemplate = ref<Template | null>(templates[0]);
        const userInput = ref('');
        const finalText = ref('');

        const generateFinalText = () => {
            if (!selectedTemplate.value) return;
            finalText.value = selectedTemplate.value.content.replace('// Your input here', `\`\`\`${userInput.value}\`\`\``);
            copyToClipboard();
        };
        const clearInputs = () => {
            userInput.value = '';
            finalText.value = selectedTemplate.value?.content || '';
        };

        const copyToClipboard = async () => {
            if (finalText.value) {
                try {
                    await navigator.clipboard.writeText(finalText.value);
                    showSnackbar.value = true;
                    setTimeout(() => { showSnackbar.value = false; }, 3000);
                } catch (err) {
                    console.error('Failed to copy: ', err);
                }
            }
        };

        return { showSnackbar, templates, selectedTemplate, userInput, finalText, generateFinalText, copyToClipboard, clearInputs };
    }
});
</script>
  
<style scoped>
/* Additional styles for select dropdown */
select {
    /* background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19 9l-7 7-7-7"></path></svg>'); */
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
}

pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}</style>
  