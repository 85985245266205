<template>
    <div class="card bg-gray-100 rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300">
        <div class="flex items-center space-x-4 mb-4">
            <img class="h-16 w-16 rounded-full" :src="tool.icon" :alt="tool.name + ' Logo'">
            <div>
                <p class="font-semibold">{{ tool.name }}</p>
                <p class="text-sm text-gray-600">{{ tool.description }}</p>

            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tool } from '@/types/types';


const ToolCard = defineComponent({
    props: {
        tool: {
            type: Object as () => Tool,
            required: true
        }
    }
});

export default ToolCard;
</script>
