<template>
    <div class="p-4">
      <h1 class="text-xl font-bold mb-4">Hashing Tools</h1>
  
      <div class="mb-4">
        <textarea v-model="inputText" class="border border-gray-300 rounded p-2 w-full h-32" placeholder="Enter text here"></textarea>
      </div>
  
      <div class="mb-4">
        <select v-model="selectedAlgorithm" class="border border-gray-300 rounded p-2 w-full">
          <option value="MD5">MD5</option>
          <option value="SHA1">SHA-1</option>
          <option value="SHA256">SHA-256</option>
          <option value="SHA384">SHA-384</option>
          <option value="SHA512">SHA-512</option>
          <option value="SHA3">SHA-3</option>
          <!-- Add more algorithms if needed -->
        </select>
      </div>
  
      <button @click="generateHash" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Generate Hash
      </button>
  
      <div v-if="generatedHash" class="mt-4">
        <p class="p-2 bg-gray-100 rounded">Generated Hash: <strong>{{ generatedHash }}</strong></p>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import CryptoJS from 'crypto-js';
  
  export default defineComponent({
    name: 'HashingTools',
    setup() {
      const inputText = ref('');
      const selectedAlgorithm = ref('MD5');
      const generatedHash = ref('');
  
      function generateHash() {
        switch (selectedAlgorithm.value) {
          case 'MD5':
            generatedHash.value = CryptoJS.MD5(inputText.value).toString();
            break;
          case 'SHA1':
            generatedHash.value = CryptoJS.SHA1(inputText.value).toString();
            break;
          case 'SHA256':
            generatedHash.value = CryptoJS.SHA256(inputText.value).toString();
            break;
          case 'SHA384':
            generatedHash.value = CryptoJS.SHA384(inputText.value).toString();
            break;
          case 'SHA512':
            generatedHash.value = CryptoJS.SHA512(inputText.value).toString();
            break;
          case 'SHA3':
            generatedHash.value = CryptoJS.SHA3(inputText.value).toString();
            break;
          // Add more cases for additional algorithms
          default:
            generatedHash.value = '';
        }
      }
  
      return { inputText, selectedAlgorithm, generatedHash, generateHash };
    }
  });
  </script>
  
  <style scoped>
  /* Tailwind utility classes handle most styling */
  </style>
  