import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card bg-gray-100 rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300" }
const _hoisted_2 = { class: "flex items-center space-x-4 mb-4" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "text-sm text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "h-16 w-16 rounded-full",
        src: _ctx.tool.icon,
        alt: _ctx.tool.name + ' Logo'
      }, null, 8, _hoisted_3),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.tool.name), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.tool.description), 1)
      ])
    ])
  ]))
}