<template>
  <div class="container mx-auto px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-6">
      <router-link v-for="tool in tools" :to="tool.route" :key="tool.name">
        <ToolCard :tool="tool"></ToolCard>
      </router-link>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import ToolCard from '@/components/ToolCard.vue';
import { Tool } from '@/types/types';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      tools: [
        { name: 'QR Code Generator', route: '/tools/qrcode', icon: 'img/qrcode_logo.png', descriptio: 'Generate QR Code' },
        { name: 'Timestamp Generator', route: '/tools/timestamp', icon: 'img/timestamp_logo.png', description: 'Generate Timestamp' },
        { name: 'Base64 Converter', route: '/tools/base64', icon: 'img/base64_logo.png', description: 'Convert Base64' },
        { name: 'UUID Generator', route: '/tools/uuid', icon: 'img/uuid_logo.png', description: 'Generate UUID' },
        { name: 'JSON Formatter', route: '/tools/json', icon: 'img/json_logo.png', description: 'Format JSON' },
        { name: 'XML Formatter', route: '/tools/xml', icon: 'img/xml_logo.png', description: 'Format XML' },
        { name: 'Image Resizer', route: '/tools/image-resizer', icon: 'img/image_resizer_logo.png', description: 'Resize Image' },
        { name: 'Markdown Previewer', route: '/tools/markdown', icon: 'img/markdown_logo.png', description: 'Preview Markdown' },
        { name: 'Web Storage Explorer', route: '/tools/web-storage', icon: 'img/web_storage_logo.png', description: 'Explore Web Storage' },
        { name: 'Password Generator', route: '/tools/password', icon: 'img/password_logo.png', description: 'Generate Password' },
        { name: 'Browser Details', route: '/tools/browser-details', icon: 'img/browser_logo.png', description: 'Get Browser Details' },
        { name: 'Color Utility', route: '/tools/color-picker', icon: 'img/color_picker_logo.png', description: 'Color Utility' },
        { name : 'Text Utility', route: '/tools/text-utility', icon: 'img/text_utility_logo.png', description: 'Text Utility' },
        { name : 'JWT Decoder', route: '/tools/jwt-decoder', icon: 'img/jwt-decoder_logo.png', description: 'JWT Decoder'},
        { name : 'Hashing Tools', route: '/tools/hashing-tools', icon: 'img/hashing_tools_logo.png', description: 'Hashing Tools'},
        { name : 'Git Cheatsheet', route: '/tools/git-cheatsheet', icon: 'img/git_cheatsheet_logo.png',description: 'Git Cheatsheet'},
        { name : 'Prompt Builder', route: '/tools/prompt-template-builder', icon: 'img/promptBuilderLogo.webp', description: 'Prompt Builder'},
        { name : 'VS Code Keyboard Shortcuts', route: '/tools/vscode-hotkeys', icon: 'img/vscode_hotkeys_logo.webp', description: 'VS Code Keyboard Shortcuts'},
       
      
      ] as Tool[],
    };
  },
  components: {
    ToolCard
  },
});
</script>

<style>
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
