<template>
    <div class="xml-formatter m-16">
      <h2>XML Formatter</h2>
      <textarea class="xml-input" v-model="xmlInput" placeholder="Enter XML"></textarea>
      <div class="button-container">
        <button class="format-btn" @click="formatXML">Format XML</button>
      </div>
      <pre class="xml-output" v-if="formattedXml">{{ formattedXml }}</pre>
      <button class="copy-btn" v-if="formattedXml" @click="copyToClipboard(formattedXml)">Copy Formatted XML</button>
      <div class="snackbar" :class="{ show: showSnackbar }">Copied to clipboard!</div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import vkbeautify from 'vkbeautify';
  
  export default defineComponent({
    name: 'XMLFormatter',
    setup() {
      const xmlInput = ref('');
      const formattedXml = ref('');
      const showSnackbar = ref(false);
  
      const formatXML = () => {
        try {
          formattedXml.value = vkbeautify.xml(xmlInput.value);
        } catch (error) {
          formattedXml.value = 'Invalid XML';
        }
      };

      const copyToClipboard = async (text: string) => {
        try {
          await navigator.clipboard.writeText(text);
          showSnackbar.value = true;
          setTimeout(() => { showSnackbar.value = false; }, 3000); // Hide after 3 seconds
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      };
  
      return { xmlInput, formattedXml, formatXML, copyToClipboard, showSnackbar };
    }
  });
  </script>
  
  <style scoped>
  .xml-formatter {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .xml-input {
    width: 80%;
    height: 100px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
  }
  
  .button-container {
    text-align: center;
    margin: 15px 0;
  }
  
  .format-btn {
  background-color: #6c757d; /* Different color, e.g., gray */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.format-btn:hover {
  background-color: #5a6268;
}
  
  .xml-output {
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .copy-btn {
    background-color: #28a745;
    color: white;
  }
  
  .copy-btn:hover {
    background-color: #1e7e34;
  }


.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
}
  </style>
  