<template>
    <div class="p-4">
      <h1 class="text-xl font-bold mb-4">Password Generator</h1>
      <div class="mb-4">
        <label for="length" class="block mb-2">Length:</label>
        <input type="number" v-model.number="passwordLength" min="6" max="20" class="border border-gray-300 rounded p-2">
      </div>
      <div class="mb-4">
        <label class="block mb-2">
          <input type="checkbox" v-model="includeNumbers" class="mr-2">
          Include Numbers
        </label>
        <label class="block mb-2">
          <input type="checkbox" v-model="includeSymbols" class="mr-2">
          Include Symbols
        </label>
      </div>
      <button @click="generatePassword" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Generate Password
      </button>
  
      <div v-if="generatedPassword" class="mt-4">
        <p class="p-2 bg-gray-100 rounded inline-block">Generated Password: <strong>{{ generatedPassword }}</strong></p>
        <button @click="copyPassword" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2">
          Copy
        </button>
      </div>
  
      <div v-if="showSnackbar" class="snackbar">
        Password copied to clipboard!
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'PasswordGenerator',
    setup() {
      const passwordLength = ref(10);
      const includeNumbers = ref(false);
      const includeSymbols = ref(false);
      const generatedPassword = ref('');
      const showSnackbar = ref(false);
  
      function generatePassword() {
        const numbers = "0123456789";
        const symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = lowercase.toUpperCase();
  
        let characters = lowercase + uppercase;
        if (includeNumbers.value) characters += numbers;
        if (includeSymbols.value) characters += symbols;
  
        let password = "";
        for (let i = 0; i < passwordLength.value; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          password += characters[randomIndex];
        }
  
        generatedPassword.value = password;
      }
  
      function copyPassword() {
        if (navigator.clipboard && generatedPassword.value) {
          navigator.clipboard.writeText(generatedPassword.value)
            .then(() => {
              showSnackbar.value = true;
              setTimeout(() => showSnackbar.value = false, 3000); // Hide snackbar after 3 seconds
            })
            .catch(err => console.error("Failed to copy password: ", err));
        }
      }
  
      return { passwordLength, includeNumbers, includeSymbols, generatedPassword, generatePassword, copyPassword, showSnackbar };
    }
  });
  </script>
  
  <style scoped>
  /* Tailwind utility classes handle most styling */
  
  .snackbar {
    visibility: visible;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    transition: visibility 0.3s, opacity 0.3s linear;
    opacity: 1;
  }
  </style>
  