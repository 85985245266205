<template>
  <div class="p-4 max-w-lg mx-auto">
    <h1 class="text-2xl font-bold text-center mb-6">JWT Decoder</h1>

    <div class="mb-6">
      <textarea v-model="jwtToken" class="border border-gray-300 rounded w-full h-32 p-2 shadow-sm"
        placeholder="Enter JWT here"></textarea>
    </div>

    <div class="text-center mb-6">
      <button @click="decodeJWT" class="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded shadow">
        Decode JWT
      </button>
    </div>

    <div v-if="decodedToken" class="bg-white shadow rounded p-4">
      <h2 class="text-lg font-semibold mb-2">Decoded JWT:</h2>
      <div>
        <h3 class="font-semibold">Header:</h3>
        <pre class="p-2 bg-gray-100 rounded">{{ decodedToken?.header }}</pre>
      </div>
      <div class="mt-4">
        <h3 class="font-semibold">Payload:</h3>
        <pre class="p-2 bg-gray-100 rounded">{{ decodedToken?.payload }}</pre>
      </div>
      <div class="mt-4">
        <h3 class="font-semibold">Signature:</h3>
        <pre class="p-2 bg-gray-100 rounded">{{ jwtSignature }}</pre>
      </div>
    </div>
  </div>
</template>

  
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { KJUR, b64utoutf8 } from 'jsrsasign';  // Import KJUR from jsrsasign

export default defineComponent({
  name: 'JWTDecoder',
  setup() {
    const jwtToken = ref('');
    const decodedToken = ref<{ header: any; payload: any } | null>(null);
    const jwtSignature = ref('');

    function decodeJWT() {
      try {
        const parts = jwtToken.value.split('.');
        if (parts.length === 3) {
          const header = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(parts[0]));
          const payload = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(parts[1]));
          decodedToken.value = { header, payload };
          jwtSignature.value = parts[2];
        } else {
          decodedToken.value = null;
          jwtSignature.value = 'Invalid JWT token.';
        }
      } catch (error) {
        decodedToken.value = null;
        jwtSignature.value = "Invalid JWT token.";
      }
    }

    return { jwtToken, decodedToken, jwtSignature, decodeJWT };
  }
});
</script>
  
<style scoped>
/* Tailwind utility classes handle most styling */
</style>
  