<template>
  <div class="qr-code-generator">
    <h2>QR Code Generator</h2>
    <input type="text" v-model="qrText" placeholder="Enter text or URL" class="qr-input" />
    <button @click="generateQR" class="qr-btn">Generate QR Code</button>
    <div v-if="qrCode" class="qr-image-container">
      <img :src="qrCode" alt="QR Code" class="qr-image" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import QRCode from 'qrcode';

export default defineComponent({
  name: 'QRCodeGenerator',
  setup() {
    const qrText = ref('');
    const qrCode = ref('');

    const generateQR = async () => {
      if (qrText.value) {
        try {
          qrCode.value = await QRCode.toDataURL(qrText.value, { width: 256, height: 256 });
        } catch (error) {
          console.error(error);
          qrCode.value = '';
        }
      }
    };

    return { qrText, qrCode, generateQR };
  }
});
</script>

<style scoped>
.qr-code-generator {
  text-align: center;
  margin: 20px;
}

.qr-input {
  width: 70%;
  padding: 10px;
  margin-bottom: 15px; /* Increased bottom margin for spacing */
  margin-right: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.qr-btn {
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; /* Added bottom margin for spacing between button and QR code */
}

.qr-btn:hover {
  background-color: #45a049;
}

.qr-image-container {
  margin-top: 20px;
}

.qr-image {
  width: 100%;
  max-width: 256px; /* Adjust QR code size */
}
</style>
