<template>
  <div class="p-4">
    <h1 class="text-xl font-bold mb-4">Browser Details</h1>
    <table class="table-auto w-full border-collapse border border-gray-400">
      <tbody>
        <tr class="bg-gray-100">
          <td class="border border-gray-300 p-2">Browser</td>
          <td class="border border-gray-300 p-2">{{ browserDetails.browser.name }} {{ browserDetails.browser.version }}</td>
        </tr>
        <tr>
          <td class="border border-gray-300 p-2">Operating System</td>
          <td class="border border-gray-300 p-2">{{ browserDetails.os.name }} {{ browserDetails.os.version }}</td>
        </tr>
        <tr class="bg-gray-100">
          <td class="border border-gray-300 p-2">Device</td>
          <td class="border border-gray-300 p-2">{{ browserDetails.device.model }} {{ browserDetails.device.type }} {{ browserDetails.device.vendor }}</td>
        </tr>
        <!-- <tr>
          <td class="border border-gray-300 p-2">CPU Architecture</td>
          <td class="border border-gray-300 p-2">{{ browserDetails.cpu.architecture }}</td>
        </tr> -->
        <tr class="bg-gray-100">
          <td class="border border-gray-300 p-2">User Agent</td>
          <td class="border border-gray-300 p-2">{{ browserDetails.ua }}</td>
        </tr>

        <tr class="bg-gray-100">
          <td class="border border-gray-300 p-2">Screen Resolution</td>
          <td class="border border-gray-300 p-2">{{ screenResolution }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import UAParser from 'ua-parser-js';

export default defineComponent({
  name: 'BrowserDetails',
  setup() {
    const parser = new UAParser();
    const browserDetails = ref(parser.getResult());

    return { browserDetails, screenResolution: `${screen.width} x ${screen.height}` };
  }
});
</script>

<style scoped>
/* Tailwind utility classes handle most styling */
</style>
