<template>
    <div class="p-4 max-w-4xl mx-auto">
        <h1 class="text-2xl font-bold text-center mb-6 text-blue-600">AI Tools for Developers</h1>
        <table class="min-w-full table-auto border-collapse border border-blue-400">
            <thead>
                <tr class="bg-yellow-200">
                    <th class="border border-blue-300 p-2 text-blue-700">Tool</th>
                    <th class="border border-blue-300 p-2 text-blue-700">Description</th>
                    <th class="border border-blue-300 p-2 text-blue-700">Best Used For</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tool in aiTools" :key="tool.name" class="hover:bg-yellow-100">
                    <td class="border border-blue-300 p-2">{{ tool.name }}</td>
                    <td class="border border-blue-300 p-2">{{ tool.description }}</td>
                    <td class="border border-blue-300 p-2">{{ tool.bestUsedFor }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AITools',
    data() {
        return {
            aiTools: [
                {
                    "name": "GPT-4 (OpenAI)",
                    "description": "An autoregressive language model that uses deep learning to produce human-like text.",
                    "bestUsedFor": "Natural language processing, chatbots, content generation."
                },
                {
                    "name": "Gemini (Google)",
                    "description": "State-of-the-art AI model excelling in natural language processing, code understanding, and problem solving.",
                    "bestUsedFor": "Advanced NLP tasks, code generation, research, general-purpose AI applications."
                },
                {
                    "name": "GitHub Copilot",
                    "description": "An AI pair programmer powered by OpenAI Codex. It helps write new code and understand and work with existing code faster.",
                    "bestUsedFor": "Code generation, software development assistance."
                },
                {
                    "name": "GitHub Copilot Chat",
                    "description": "An extension of GitHub Copilot, offering conversational interactions to help understand and write code, leveraging a dialogue-based interface.",
                    "bestUsedFor": "Interactive coding assistance, code explanation, problem solving."
                },
                {
                    "name": "Hugging Face Transformers",
                    "description": "Open-source library of pre-trained NLP models and tools for various tasks.",
                    "bestUsedFor": "Text classification, summarization, translation, question answering."
                },
                {
                    "name": "TensorFlow (Google)",
                    "description": "An open-source software library for dataflow and differentiable programming across a range of tasks.",
                    "bestUsedFor": "Machine learning models, neural networks."
                },
                {
                    "name": "PyTorch (Facebook)",
                    "description": "An open-source machine learning library based on the Torch library, used for applications such as computer vision and natural language processing.",
                    "bestUsedFor": "Deep learning, academic research, prototyping."
                },
                {
                    "name": "Azure AI (Microsoft)",
                    "description": "A set of AI services and cognitive APIs to help you build intelligent apps.",
                    "bestUsedFor": "Vision, speech, language, decision-making tasks."
                },
                {
                    "name": "Scikit-learn",
                    "description": "A free software machine learning library for the Python programming language. It features various classification, regression and clustering algorithms.",
                    "bestUsedFor": "Data mining, data analysis, machine learning tasks."
                },
                {
                    "name": "Keras",
                    "description": "An open-source software library that provides a Python interface for artificial neural networks. Keras acts as an interface for the TensorFlow library.",
                    "bestUsedFor": "Deep learning, neural networks, rapid prototyping."
                },

                {
                    "name": "OpenCV",
                    "description": "An open-source computer vision and machine learning software library. It has C++, Python, Java, and MATLAB interfaces and supports Windows, Linux, Android, and macOS.",
                    "bestUsedFor": "Image and video analysis, computer vision applications."
                },
                {
                    "name": "Apache MXNet",
                    "description": "An open-source deep learning framework suited for flexible research prototyping and production.",
                    "bestUsedFor": "Deep learning, cloud services integration."
                },
                {
                    "name": "Caffe (Berkeley AI Research)",
                    "description": "A deep learning framework made with expression, speed, and modularity in mind.",
                    "bestUsedFor": "Convolutional neural networks, image processing."
                }
            ]

        };
    }
});
</script>
  
<style scoped>
/* Tailwind utility classes handle most styling */
</style>
  